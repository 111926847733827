import React, { useContext, useEffect, useState } from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { useHistory } from 'react-router-dom';
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Form from '../shared/Form';
import FormTextField from '../shared/FormTextField';
import useAddOrganization from '../../hooks/useAddOrganization';
import { InformationBannerContext } from '../../contexts/InformationBannerContext';
import ControlledFormSelectionField from '../shared/ControlledFormSelectionField';
import useCountryStateCity from '../../hooks/useCountryStateCity';
import ControlledFormCheckboxField from '../shared/ControlledFormCheckboxField';
import FormNestedArrayField from '../shared/FormNestedArrayField';
import ConfirmationDialog from '../shared/ConfirmationDialog';

const organizationSchema = yup.object().shape({
  name: yup.string().required('Organization name is required'),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required'),
  country: yup.string().required('Country is required'),
  emrEnabled: yup.boolean().required(),
  emrGateway: yup
    .string()
    .oneOf(['philips', 'vendor_services'], 'Invalid EMR Gateway')
    .nullable()
    .optional(),
  gateway: yup.object().when(['emrEnabled', 'emrGateway'], {
    is: (emrEnabled, emrGateway) =>
      emrEnabled && emrGateway === 'vendor_services',
    then: yup
      .object({
        authentication_url: yup
          .string()
          .url('Must be a valid URL')
          .required('Authentication URL is required'),
        vendor_services_url: yup
          .string()
          .url('Must be a valid URL')
          .required('Vendor Services URL is required'),
        epic_user_id: yup.string().required('Epic User ID is required'),
        pst_zscore_id: yup.string().required('PST Z-Score ID is required'),
        pst_raw_score_id: yup.string().required('PST Raw Score ID is required'),
        pst_percentile_id: yup
          .string()
          .required('PST Percentile ID is required'),
        vmt_zscore_id: yup.string().required('VMT Z-Score ID is required'),
        vmt_raw_score_id: yup.string().required('VMT Raw Score ID is required'),
        vmt_percentile_id: yup
          .string()
          .required('VMT Percentile ID is required'),
        providers: yup
          .array()
          .of(
            yup.object().shape({
              provider_id: yup.string().required('Provider ID is required'),
              id_type: yup.string().required('ID Type is required'),
              department_id: yup.string().required('Department ID is required'),
              department_type: yup
                .string()
                .required('Department Type is required'),
            })
          )
          .nullable(),
        subgroups: yup
          .array()
          .of(
            yup.object().shape({
              subgroup_id: yup.string().required('Subgroup ID is required'),
              id_type: yup.string().required('ID Type is required'),
              department_id: yup.string().required('Department ID is required'),
              department_type: yup
                .string()
                .required('Department Type is required'),
            })
          )
          .nullable(),
        departments: yup
          .array()
          .of(
            yup.object().shape({
              department_id: yup.string().required('Department ID is required'),
              type: yup.string().required('Type is required'),
            })
          )
          .nullable(),
      })
      .required('Gateway information is required'),
    otherwise: yup.object().strip(),
  }),
});

const AddOrganization = () => {
  const history = useHistory();
  const setBannerMessage = useContext(InformationBannerContext);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const defaultValues = {
    country: 'US',
    emrEnabled: false,
    emrGateway: '',
  };

  // Form handler
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(organizationSchema),
    defaultValues,
  });

  // Watch fields for conditional rendering
  const emrEnabled = watch('emrEnabled');
  const emrGateway = watch('emrGateway');

  // Field arrays for nested array fields inside gateway
  const {
    fields: departmentFields,
    append: appendDepartment,
    remove: removeDepartment,
  } = useFieldArray({
    control,
    name: 'gateway.departments',
  });

  const {
    fields: subgroupFields,
    append: appendSubgroup,
    remove: removeSubgroup,
  } = useFieldArray({
    control,
    name: 'gateway.subgroups',
  });

  const {
    fields: providerFields,
    append: appendProvider,
    remove: removeProvider,
  } = useFieldArray({
    control,
    name: 'gateway.providers',
  });

  const clearGatewayFields = () => {
    setValue('gateway', {});
    setValue('emrGateway', '');
  };

  useEffect(() => {
    if (!emrEnabled) {
      clearGatewayFields();
    }
  }, [emrEnabled]);

  // API handler
  const mutation = useAddOrganization((response) => {
    setBannerMessage('Added organization successfully.');
    history.push(`/organizations/${response.data.id}`);
  });

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      emr_enabled: data.emrEnabled, // Map to snake_case for API
      emr_gateway: data.emrGateway,
    };

    if (!data.emrEnabled) {
      delete formattedData.gateway;
      formattedData.emr_gateway = null;
    }

    // Remove camelCase properties
    delete formattedData.emrEnabled;
    delete formattedData.emrGateway;

    mutation.mutate(formattedData);
  };

  const handleEmrDisable = (e, onChange) => {
    if (
      !e.target.checked &&
      Object.keys(getValues('gateway') || {}).length > 0
    ) {
      // Prevent the checkbox from changing state
      e.preventDefault();
      setIsDialogOpen(true);
    } else {
      // Update the form state
      onChange(e.target.checked);
    }
  };

  const handleDisableEmr = () => {
    // Set emrEnabled to false and clear gateway fields
    setValue('emrEnabled', false);
    clearGatewayFields();
    setIsDialogOpen(false);
  };

  const { countries, states, cities } = useCountryStateCity(
    watch('country'),
    watch('state'),
    watch('city'),
    setValue,
    getValues
  );

  return (
    <>
      <ConfirmationDialog
        title="Disable EMR"
        message="Disabling EMR will clear all gateway settings. Are you sure?"
        open={isDialogOpen}
        setOpen={setIsDialogOpen}
        destructiveButtonTitle="Disable EMR"
        destructiveFunction={handleDisableEmr}
      />
      <Form
        title="New Organization"
        description="Enter details about your organization below."
        onSubmit={handleSubmit(onSubmit)}
        submitText="Create Organization"
        submitIcon={<PlusIcon />}
        cancelLink="/organizations"
        isPosting={mutation.isLoading}
        isError={mutation.isError}
        error={mutation.error}
        validationErrors={errors}
        resourceType="organization"
      >
        {/* Organization basic fields */}
        <FormTextField
          register={register}
          error={errors.name}
          title="Organization Name"
          formKey="name"
        />
        <ControlledFormSelectionField
          error={errors.country}
          title="Country"
          formKey="country"
          options={countries}
          control={control}
          defaultValue={defaultValues.country}
        />
        <ControlledFormSelectionField
          error={errors.state}
          title="State"
          formKey="state"
          options={states}
          control={control}
        />
        <ControlledFormSelectionField
          error={errors.city}
          title="City"
          formKey="city"
          options={cities}
          control={control}
        />

        <ControlledFormCheckboxField
          error={errors.emrEnabled}
          title="EMR Enabled"
          formKey="emrEnabled"
          control={control}
          onChange={handleEmrDisable}
        />

        {/* EMR Gateway Selection */}
        {emrEnabled && (
          <ControlledFormSelectionField
            error={errors.emrGateway}
            title="EMR Gateway"
            formKey="emrGateway"
            options={[
              { title: 'Philips', value: 'philips' },
              { title: 'Vendor Services', value: 'vendor_services' },
            ]}
            control={control}
          />
        )}

        {/* Conditional fields based on emrGateway being 'vendor_services' */}
        {emrEnabled && emrGateway === 'vendor_services' && (
          <>
            <FormTextField
              register={register}
              error={errors.gateway?.authentication_url}
              title="Authentication URL"
              formKey="gateway.authentication_url"
            />
            <FormTextField
              register={register}
              error={errors.gateway?.vendor_services_url}
              title="Vendor Services URL"
              formKey="gateway.vendor_services_url"
            />
            <FormTextField
              register={register}
              error={errors.gateway?.epic_user_id}
              title="Epic User ID"
              formKey="gateway.epic_user_id"
            />
            <FormTextField
              register={register}
              error={errors.gateway?.specialty}
              title="Specialty"
              formKey="gateway.specialty"
            />
            <FormTextField
              register={register}
              error={errors.gateway?.pst_zscore_id}
              title="PST Z-Score ID"
              formKey="gateway.pst_zscore_id"
            />
            <FormTextField
              register={register}
              error={errors.gateway?.pst_raw_score_id}
              title="PST Raw Score ID"
              formKey="gateway.pst_raw_score_id"
            />
            <FormTextField
              register={register}
              error={errors.gateway?.pst_percentile_id}
              title="PST Percentile ID"
              formKey="gateway.pst_percentile_id"
            />
            <FormTextField
              register={register}
              error={errors.gateway?.vmt_zscore_id}
              title="VMT Z-Score ID"
              formKey="gateway.vmt_zscore_id"
            />
            <FormTextField
              register={register}
              error={errors.gateway?.vmt_raw_score_id}
              title="VMT Raw Score ID"
              formKey="gateway.vmt_raw_score_id"
            />
            <FormTextField
              register={register}
              error={errors.gateway?.vmt_percentile_id}
              title="VMT Percentile ID"
              formKey="gateway.vmt_percentile_id"
            />

            {/* Nested array fields inside gateway */}
            <FormNestedArrayField
              title="Departments"
              register={register}
              formKey="gateway.departments"
              fields={departmentFields}
              append={appendDepartment}
              remove={removeDepartment}
              control={control}
              errors={errors.gateway?.departments}
              fieldStructure={[
                { name: 'department_id', label: 'Department ID', type: 'text' },
                { name: 'type', label: 'Type', type: 'text' },
              ]}
            />

            <FormNestedArrayField
              title="Subgroups"
              register={register}
              formKey="gateway.subgroups"
              fields={subgroupFields}
              append={appendSubgroup}
              remove={removeSubgroup}
              control={control}
              errors={errors.gateway?.subgroups}
              fieldStructure={[
                { name: 'subgroup_id', label: 'Subgroup ID', type: 'text' },
                { name: 'id_type', label: 'ID Type', type: 'text' },
                { name: 'department_id', label: 'Department ID', type: 'text' },
                {
                  name: 'department_type',
                  label: 'Department Type',
                  type: 'text',
                },
              ]}
            />

            <FormNestedArrayField
              title="Providers"
              formKey="gateway.providers"
              register={register}
              fields={providerFields}
              append={appendProvider}
              remove={removeProvider}
              control={control}
              errors={errors.gateway?.providers}
              fieldStructure={[
                { name: 'provider_id', label: 'Provider ID', type: 'text' },
                { name: 'id_type', label: 'ID Type', type: 'text' },
                { name: 'department_id', label: 'Department ID', type: 'text' },
                {
                  name: 'department_type',
                  label: 'Department Type',
                  type: 'text',
                },
              ]}
            />
          </>
        )}
      </Form>
    </>
  );
};

export default AddOrganization;
