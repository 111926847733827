import React, { useState } from 'react';
import { Country, State } from 'country-state-city';
import { useParams } from 'react-router-dom';
import {
  PencilIcon,
  CheckIcon,
  ClipboardCopyIcon,
} from '@heroicons/react/solid';
import DetailItem from '../shared/DetailItem';
import DetailView from '../shared/DetailView';
import LoadingDetail from '../shared/LoadingDetail';
import useOrganization from '../../hooks/useOrganization';
import DetailDateItem from '../shared/DetailDateItem';
import Button from '../shared/Button';
import DisplayError from '../shared/DisplayError';
import DetailList from '../shared/DetailList';

const getState = ({ state: stateCode, country: countryCode }) => {
  const state = State.getStateByCodeAndCountry(stateCode, countryCode);
  return state ? state.name : stateCode;
};

const getCountry = ({ country: countryCode }) => {
  const country = Country.getCountryByCode(countryCode);
  return country ? country.name : countryCode;
};

const ShowOrganization = () => {
  const { organizationID } = useParams();
  const {
    data: organization,
    isLoading,
    isError,
    error,
  } = useOrganization(organizationID);

  const [copied, setCopied] = useState(false);

  if (isLoading) {
    return <LoadingDetail />;
  }

  if (isError) {
    return <DisplayError error={error} />;
  }

  // Destructure and rename properties from organization
  const {
    emr_enabled: emrEnabled,
    emr_gateway: emrGateway,
    name,
    city,
    state,
    country,
    id,
    created_at: createdAt,
    gateway,
  } = organization;

  return (
    <DetailView
      title={name}
      description="Organization Details"
      backLink="/organizations"
      additionalButtons={[
        <Button
          key="edit"
          name="Edit"
          icon={<PencilIcon />}
          link={`/organizations/${organizationID}/edit`}
          isSecondaryStyle
        />,
      ]}
    >
      <>
        <DetailItem
          title="Organization ID"
          value={id}
          additionalButtons={[
            <Button
              additionalStyle="copy-to-clipboard"
              key="copy"
              isSecondaryStyle
              name={copied ? 'Copied to Clipboard!' : 'Copy to Clipboard'}
              icon={copied ? <CheckIcon /> : <ClipboardCopyIcon />}
              onClick={() => {
                navigator.clipboard.writeText(id);
                setCopied(true);
              }}
            />,
          ]}
        />
        <DetailItem title="Organization Name" value={name} />
        <DetailItem title="Country" value={getCountry({ country })} />
        <DetailItem title="State" value={getState({ state, country })} />
        <DetailItem title="City" value={city} />
        <DetailItem title="EMR Enabled" value={emrEnabled ? 'True' : 'False'} />

        {/* EMR Gateway Details */}
        {emrEnabled && (
          <DetailItem title="EMR Gateway" value={emrGateway || 'N/A'} />
        )}

        {/* Conditional fields based on emrGateway being 'vendor_services' */}
        {emrEnabled && emrGateway === 'vendor_services' && gateway && (
          <>
            <DetailItem
              title="Authentication URL"
              value={gateway.authentication_url}
            />
            <DetailItem
              title="Vendor Services URL"
              value={gateway.vendor_services_url}
            />
            <DetailItem title="Epic User ID" value={gateway.epic_user_id} />
            <DetailItem title="Specialty" value={gateway.specialty} />
            <DetailItem title="PST Z-Score ID" value={gateway.pst_zscore_id} />
            <DetailItem
              title="PST Raw Score ID"
              value={gateway.pst_raw_score_id}
            />
            <DetailItem
              title="PST Percentile ID"
              value={gateway.pst_percentile_id}
            />
            <DetailItem title="VMT Z-Score ID" value={gateway.vmt_zscore_id} />
            <DetailItem
              title="VMT Raw Score ID"
              value={gateway.vmt_raw_score_id}
            />
            <DetailItem
              title="VMT Percentile ID"
              value={gateway.vmt_percentile_id}
            />

            {/* Departments */}
            <DetailList
              title="Departments"
              items={gateway.departments || []}
              renderItem={(dept) => (
                <>
                  <p>
                    <span className="font-semibold">Department ID:</span>{' '}
                    {dept.department_id}
                  </p>
                  <p>
                    <span className="font-semibold">Type:</span> {dept.type}
                  </p>
                </>
              )}
            />

            {/* Subgroups */}
            <DetailList
              title="Subgroups"
              items={gateway.subgroups || []}
              renderItem={(subgroup) => (
                <>
                  <p>
                    <span className="font-semibold">Subgroup ID:</span>{' '}
                    {subgroup.subgroup_id}
                  </p>
                  <p>
                    <span className="font-semibold">ID Type:</span>{' '}
                    {subgroup.id_type}
                  </p>
                  <p>
                    <span className="font-semibold">Department ID:</span>{' '}
                    {subgroup.department_id}
                  </p>
                  <p>
                    <span className="font-semibold">Department Type:</span>{' '}
                    {subgroup.department_type}
                  </p>
                </>
              )}
            />

            {/* Providers */}
            <DetailList
              title="Providers"
              items={gateway.providers || []}
              renderItem={(provider) => (
                <>
                  <p>
                    <span className="font-semibold">Provider ID:</span>{' '}
                    {provider.provider_id}
                  </p>
                  <p>
                    <span className="font-semibold">ID Type:</span>{' '}
                    {provider.id_type}
                  </p>
                  <p>
                    <span className="font-semibold">Department ID:</span>{' '}
                    {provider.department_id}
                  </p>
                  <p>
                    <span className="font-semibold">Department Type:</span>{' '}
                    {provider.department_type}
                  </p>
                </>
              )}
            />
          </>
        )}

        <DetailDateItem title="Creation Date" value={createdAt} />
      </>
    </DetailView>
  );
};

export default ShowOrganization;
