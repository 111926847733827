import React from 'react';
import { ArrowLeftIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import Button from './Button';
import '../../css/shared/Form.css';
import FormError from './FormError';
import { displayableError } from '../../utils/format';

const formHasErrors = (validationErrors) => {
  if (validationErrors === undefined) {
    return false;
  }
  return Object.keys(validationErrors).length > 0;
};

const generateErrorMessage = (validationErrors) => {
  if (Object.keys(validationErrors).length > 1) {
    return `Please correct the ${
      Object.keys(validationErrors).length
    } validation errors below.`;
  }
  return 'Please correct the validation error below.';
};

const Form = ({
  title,
  description,
  children,
  onSubmit,
  submitText,
  submitIcon,
  cancelLink,
  isPosting,
  isError,
  error,
  validationErrors,
  resourceType,
}) => (
  <div className="form">
    <form
      id="form"
      className="form-outer"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className="form-inner">
        <div className="form-header">
          <div>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>

          {formHasErrors(validationErrors) && (
            <FormError message={generateErrorMessage(validationErrors)} />
          )}
          <div className="form-children">{children}</div>
        </div>
      </div>

      <div className="form-tray">
        <div className="form-error">
          {isError && <p>{displayableError(error, resourceType)}.</p>}
        </div>
        <div className="form-buttons">
          {cancelLink && (
            <Button
              name="Cancel"
              icon={<ArrowLeftIcon />}
              link={cancelLink}
              isSecondaryStyle
            />
          )}
          <Button
            type="submit"
            name={submitText}
            icon={submitIcon}
            isLoading={isPosting}
            disabled={isPosting}
          />
        </div>
      </div>
    </form>
  </div>
);

Form.defaultProps = {
  error: undefined,
  cancelLink: undefined,
  validationErrors: undefined,
  resourceType: undefined,
};

Form.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  submitIcon: PropTypes.element.isRequired,
  cancelLink: PropTypes.string,
  isPosting: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.object,
  validationErrors: PropTypes.object,
  resourceType: PropTypes.string,
};

export default Form;
