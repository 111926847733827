// DetailList.js

import React from 'react';
import PropTypes from 'prop-types';

const DetailList = ({ title, items, renderItem }) => (
  <div className="mb-6">
    <h4 className="mb-2 text-lg font-bold">{title}</h4>
    {items && items.length > 0 ? (
      <div className="space-y-4">
        {items.map((item) => (
          <div
            key={
              item.id ||
              item.subgroup_id ||
              item.department_id ||
              item.provider_id
            }
            className="border border-gray-300 p-4 rounded-md bg-gray-50"
          >
            {renderItem(item)}
          </div>
        ))}
      </div>
    ) : (
      <p>No {title}</p>
    )}
  </div>
);

DetailList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
};

export default DetailList;
