import React from 'react';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import { ExclamationCircleIcon } from '@heroicons/react/solid';
import '../../css/shared/FormTextField.css';
import { convertErrorToReadableText } from '../../utils/format';

const ControlledFormCheckboxField = ({
  title,
  formKey,
  error,
  control,
  onChange: customOnChange, // Optional custom onChange handler
  dataTestID,
}) => (
  <div className="form-textfield-grid">
    <label htmlFor={formKey} className="form-label">
      {title}
    </label>
    <div className="form-input">
      <Controller
        control={control}
        name={formKey}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <input
            type="checkbox"
            id={formKey}
            name={formKey}
            className={error ? 'form-textfield-error' : 'form-checkbox'}
            data-testid={dataTestID}
            onChange={(e) => {
              if (customOnChange) {
                customOnChange(e, onChange);
              } else {
                onChange(e.target.checked);
              }
            }}
            onBlur={onBlur}
            checked={!!value}
            ref={ref}
          />
        )}
      />
      {error && (
        <div className="form-textfield-error">
          <ExclamationCircleIcon className="form-icon" />
        </div>
      )}
      <div className="form-error-container">
        {error && (
          <span className="form-error-text">
            {convertErrorToReadableText(error.message)}
          </span>
        )}
      </div>
    </div>
  </div>
);

ControlledFormCheckboxField.propTypes = {
  title: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  error: PropTypes.object,
  control: PropTypes.object.isRequired,
  onChange: PropTypes.func, // Optional custom onChange handler
  dataTestID: PropTypes.string,
};

ControlledFormCheckboxField.defaultProps = {
  error: undefined,
  onChange: undefined,
  dataTestID: undefined,
};

export default ControlledFormCheckboxField;
