import React from 'react';
import { PlusIcon, MinusIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';

const FormNestedArrayField = ({
  title,
  formKey,
  fields,
  append,
  remove,
  register,
  errors,
  fieldStructure,
}) => {
  const createEmptyItem = () =>
    fieldStructure.reduce((acc, field) => {
      acc[field.name] = '';
      return acc;
    }, {});

  return (
    <div className="mt-6">
      <label className="block text-sm font-medium text-gray-700">{title}</label>
      {fields.map((item, index) => (
        <div key={item.id} className="mt-4 p-4 border rounded-md">
          {fieldStructure.map((field) => (
            <div key={field.name} className="mt-2">
              <label className="block text-sm font-medium text-gray-700">
                {field.label}
              </label>
              <input
                {...register(`${formKey}[${index}].${field.name}`)}
                defaultValue={item[field.name] || ''}
                type={field.type}
                className={`mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md ${
                  errors && errors[index] && errors[index][field.name]
                    ? 'border-red-500'
                    : ''
                }`}
              />
              {errors && errors[index] && errors[index][field.name] && (
                <p className="mt-1 text-sm text-red-600">
                  {errors[index][field.name]?.message}
                </p>
              )}
            </div>
          ))}
          <button
            type="button"
            onClick={() => remove(index)}
            className="mt-4 inline-flex items-center px-2 py-1 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700"
          >
            <MinusIcon className="h-4 w-4" />
            <span className="ml-1">Remove {title.slice(0, -1)}</span>
          </button>
        </div>
      ))}
      <button
        type="button"
        onClick={() => append(createEmptyItem())}
        className="px-2 py-1 mt-4 qr8-button"
      >
        <PlusIcon className="h-4 w-4" />
        <span className="ml-1">Add {title.slice(0, -1)}</span>
      </button>
    </div>
  );
};

FormNestedArrayField.defaultProps = {
  errors: undefined,
};

FormNestedArrayField.propTypes = {
  title: PropTypes.string.isRequired,
  formKey: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  append: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  fieldStructure: PropTypes.array.isRequired,
};

export default FormNestedArrayField;
